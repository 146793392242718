/* istanbul ignore file */
/* eslint-disable import/no-named-as-default-member */
import { createGlobalStyle } from 'styled-components';

import theme from '../../utils/theme';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html,
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
    padding: 0;
  }
  html, body {
    height: 100%;
  }
  body {
    font-family: ${theme.fonts.body};
    font-size: 16px;
    line-height: ${theme.lineHeights.body};
    color: ${theme.colors.text};
    scroll-behavior: smooth;
    font-display: swap;
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    font-weight: ${theme.fontWeights.heading};
    line-height: ${theme.lineHeights.heading};
    color: ${theme.colors.blue};

    & > a {
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
      text-decoration: inherit;
    }
  }
  p + p {
    margin-top: 1em;
  }
  a {
    color: ${theme.colors.blue40};
    font-weight: 600;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
  button, input {
    outline-color: ${theme.colors.teal};
  }
  ul {
    margin: 0;
    padding: 0;
  }
  img, video {
    height: auto;
    max-width: 100%;
    flex-shrink: 0;
  }
  sup, sub {
    font-size: ${theme.fontSizes[0]};
    line-height: 1;
  }
  /* The entire app is surrounded by a div with this id, which is also the first/only
  child of body */
  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .lazyload,
  .lazyloading {
    opacity: 0;
  }
  .lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
  .grecaptcha-badge { visibility: hidden; }
`;

export default GlobalStyle;
