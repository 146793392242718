import { poppins } from 'utils/fonts';
/* eslint-disable prefer-destructuring */
export const colors = {
  text: '#515966',
  background: '#fff',
  backgroundAlt: '#EFF1F4',
  white: '#ffffff',
  offwhite: '#f8f9f9',
  gray10: '#0E101E',
  gray20: '#1B1E2D',
  gray30: '#363B49',
  gray40: '#515966',
  gray50: '#6C7682',
  gray60: '#87939F',
  gray70: '#9FA9B2',
  gray80: '#C3C9CF',
  gray90: '#DBDFE2',
  gray100: '#EFF1F4',
  blue: '#022a4d',
  blue10: '#000025',
  blue20: '#02223E',
  blue40: '#375279',
  blue50: '#5F7594',
  blue60: '#9BA9BC',
  blue70: '#D7DCE4',
  blue80: '#E1E5EB',
  blue90: '#EDF2F8',
  blue100: '#F7F9FC',
  teal: '#14B5AB',
  teal10: '#164D55',
  teal20: '#156266',
  teal30: '#158D8A',
  teal40: '#15A29B',
  teal60: '#5BCBC4',
  teal70: '#8ADAD5',
  teal80: '#A1E1DD',
  teal90: '#D0F0EE',
  teal100: '#E8F8F7',
  yellow: '#FAC069',
  yellow20: '#FCCE76',
  yellow30: '#FDDB82',
  red: '#f7230a',
  red50: '#ff8585',
  red100: '#fef0ee',
  alertRedLight: '#fae0e8',
};

export const gradients = {
  blueBlue: `radial-gradient(100% 2805.09% at 0% 50%, ${colors.blue} 0%, ${colors.blue50} 100%)`,
  tealTeal: `radial-gradient(100% 2805.09% at 0% 50%, ${colors.teal10} 0%, ${colors.teal} 100%)`,
};

export const shadows = {
  card: '0 0 15px rgba(0, 0, 0, 0.1)',
  header: '0px 4px 24px rgba(0, 0, 0, 0.1)',
  image: '0px 14px 24px rgba(0, 0, 0, 0.25)',
  buttonFocus: `0 0 0 0.25rem ${colors.gray90}`,
};

const theme = {
  colors,
  gradients,
  fonts: {
    body: poppins.style.fontFamily,
    heading: poppins.style.fontFamily,
  },
  fontSizes: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '2rem',
    '3rem',
    '4rem',
    '6rem',
    '8rem',
  ],
  fontWeights: {
    light: 300,
    body: 400,
    heading: 600,
    navigation: 600,
    link: 600,
    button: 600,
    bold: 700,
  },
  // 576px, 768px, 992px, 1264px
  breakpoints: ['36em', '48em', '62em', '79em'],
  lineHeights: {
    body: 1.5,
    bodyLarge: 1.6,
    heading: 1.2,
    button: 1.5,
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 256, 512],
  sizes: {
    contentWidth: 1200,
    textWidth: 640,
    navbarHeightMobile: 64,
    navbarHeightDesktop: 80,
    searchBarHeightMobile: 96,
    searchBarHeightDesktop: 48,
  },
  radii: {
    default: 4,
    small: 2,
    large: 8,
    extraLarge: 16,
    pill: 30,
    circle: 99999,
  },
  shadows,
};

// BREAKPOINT & MEDIA QUERY ALIASES
theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

theme.mediaQueries = {
  sm: `@media screen and (min-width: ${theme.breakpoints.sm})`,
  md: `@media screen and (min-width: ${theme.breakpoints.md})`,
  lg: `@media screen and (min-width: ${theme.breakpoints.lg})`,
  xl: `@media screen and (min-width: ${theme.breakpoints.xl})`,
};

// TYPOGRAPHY ALIASES
theme.fontSizes.body = theme.fontSizes[2];
theme.fontSizes.bodyLarge = theme.fontSizes[3];
theme.fontSizes.small = theme.fontSizes[0];

export default theme;
