/* eslint-disable no-console */
/* eslint-disable camelcase */
import { parseCookies } from 'nookies';

import getExpiryRecord from './getExpiryRecord';

/**
 * @typedef {Object} utmMap
 * @property {string} [utm_source]
 * @property {string} [utm_medium]
 * @property {string} [utm_campaign]
 * @property {string} [utm_content]
 * @property {string} [utm_term]
 * @property {string} [partner_customer_id]
 * @property {string} [promo_code]
 */

const TRACKING_PARAMS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'company_id',
  'promo_code',
  'irclickid',
];
const DEFAULT_PARAMS = {
  gclid: '',
  msclkid: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_content: '',
  utm_term: '',
  partner_customer_id: '',
  promo_code: '',
  irclickid: '',
};
export const TRACKING_PARAM_STORAGE_KEY = 'hin_params';
const GCLID_STORAGE_KEY = 'gclid';
const WBRAID_STORAGE_KEY = 'wbraid';
const MSCLKID_STORAGE_KEY = '_uetmsclkid';
const MSCLKID_EXP_STORAGE_KEY = '_uetmsclkid_exp';

/**
 * @type {number} Length of a "session" in seconds
 */
export const SESSION_LENGTH = 24 * 60 * 60;

const isExpired = (expiryDate) => !expiryDate || new Date().getTime() > expiryDate;

/**
 * Sets the value of the `gclid` query param in Local Storage if valid
 *
 * @param   {Object} query The query string parsed into an object
 * @returns {String}       The value of the GCLID parameter or an empty string if
 *                         non-existent or invalid
 */
const setGclid = (query) => {
  const gclidParam = query.gclid ?? '';
  const gclsrcParam = query.gclsrc;

  const isGclsrcValid = !gclsrcParam || gclsrcParam.includes('aw');

  if (gclidParam && isGclsrcValid) {
    try {
      // gclid expires in 90 days
      const gclidRecord = getExpiryRecord(gclidParam, 90 * 24 * 60 * 60 * 1000);
      localStorage.setItem(GCLID_STORAGE_KEY, JSON.stringify(gclidRecord));
    } catch (err) {
      console.error('Error setting GCLID', err);
    }
  }

  return gclidParam;
};

/**
 * Gets the GCLID value from the query string if it exists or returns the value
 * from local storage if it exists and is valid
 *
 * @param   {Object} query The query string parsed into an object
 * @returns {String}       The value of the GCLID parameter or an empty string if
 *                         non-existent or invalid
 */
const getGclid = (query) => {
  if (query.gclid) return setGclid(query);

  const existingRecord = JSON.parse(localStorage.getItem(GCLID_STORAGE_KEY));
  const isExistingValid = existingRecord && !isExpired(existingRecord.expiryDate);

  if (isExistingValid) {
    return existingRecord.value;
  }

  return '';
};

/**
 * Returns the value of the `msclkid` query parameter, or gets it from local storage.
 * We never set the MSCLKID value ourselves, since this is done automatically by Bing ads.
 *
 * @param   {Object} query The query string parsed into an object
 * @returns {String}       The value of the MSCLKID parameter or an empty string if
 *                         non-existent or invalid
 */
const getMsclkid = (query) => {
  const msclkid = localStorage.getItem(MSCLKID_STORAGE_KEY) ?? query.msclkid;
  const msclkidExp = localStorage.getItem(MSCLKID_EXP_STORAGE_KEY);
  const isMsclkidValid = query.msclkid || (msclkid && !isExpired(msclkidExp));

  if (isMsclkidValid) {
    return msclkid;
  }

  return '';
};

const getWbraid = (query) => {
  if (query.wbraid) {
    localStorage.setItem(WBRAID_STORAGE_KEY, query.wbraid);
    return query.wbraid;
  }
  return localStorage.getItem(WBRAID_STORAGE_KEY) ?? '';
};

export const getFacebookParams = () => {
  if (typeof window === 'undefined') return {};

  const cookies = parseCookies();

  return {
    fbp: cookies._fbp ?? '',
    fbc: cookies._fbc ?? '',
  };
};

/**
 * Parses the query string object for UTM parameters and returns them if they exist
 *
 * @param   {Object} query The query string parsed into an object
 * @returns {utmMap} An object containing the UTM param values or empty strings
 */
const getTrackingParamsFromQuery = (query) => {
  const result = {};
  TRACKING_PARAMS.forEach((param) => {
    let queryParam = query[param];
    if (queryParam?.length) {
      // use the first item in the array if we get multiple values
      if (Array.isArray(queryParam)) {
        [queryParam] = queryParam;
      }
      if (param === 'company_id') {
        result.partner_customer_id = queryParam;
      } else {
        result[param] = queryParam;
      }
    }
  });

  return result;
};

/**
 *
 * @param {Object} query An object containing a map of query parameters to their original values
 */
const decodeQueryValues = (query) => {
  const result = {};

  Object.keys(query).forEach((key) => {
    result[key] = decodeURIComponent(query[key]);
  });

  return result;
};

/**
 * Checks cookie for saved UTM parameters and returns them if they exist
 * and are valid.
 * @returns {utmMap} An object containing the non-empty UTM parameters
 */
const getTrackingParamsFromStorage = () => {
  try {
    const cookies = parseCookies();
    const existingCookie = cookies[TRACKING_PARAM_STORAGE_KEY];

    if (existingCookie) {
      const existingRecord = Object.fromEntries(new URLSearchParams(existingCookie));

      if (existingRecord) {
        return decodeQueryValues(existingRecord);
      }
    }
  } catch (err) {
    console.error(`Error getting UTM params from cookie`, err);
  }

  return {};
};

/**
 * Sets the given UTM params in a cookie with a 24 hour expiration
 * @param {utmMap} trackingParams An object containing the non-empty UTM params
 */
const setTrackingParamsInStorage = (trackingParams) => {
  try {
    const domain =
      process.env.NEXT_PUBLIC_HI_ENV === 'development'
        ? 'localhost'
        : '.humaninterest.com';
    const cookieValue = new URLSearchParams(trackingParams).toString();
    // this should only ever run in the browser
    // We don't use nookies here, since they do some weird serializing
    document.cookie = `${TRACKING_PARAM_STORAGE_KEY}=${cookieValue}; max-age=${SESSION_LENGTH}; path=/; domain=${domain}`;
  } catch (err) {
    console.error(`Error storing UTM params`, err);
  }
};

/**
 * Gets the UTM params from the query string object if they exist or returns
 * the values set in a cookie
 * @param {object} query An object containing the query string parameters
 * @returns {utmMap} An object containing the non-empty UTM params
 */
const getTrackingParams = (query) => {
  const queryParams = decodeQueryValues(getTrackingParamsFromQuery(query));
  if (Object.keys(queryParams).length) {
    setTrackingParamsInStorage(queryParams);
    return queryParams;
  }

  return getTrackingParamsFromStorage();
};

/**
 *
 * @param   {Object} query The query string parsed into an object
 * @returns {Object}       An object containing the tracking params as key:value pairs
 */
const getOrSetTrackingParams = (query = {}) => {
  if (typeof window === 'undefined') return {};

  try {
    const trackingParams = {
      ...DEFAULT_PARAMS,
      gclid: getGclid(query),
      msclkid: getMsclkid(query),
      wbraid: getWbraid(query),
      ...getFacebookParams(),
      ...getTrackingParams(query),
    };

    return trackingParams;
  } catch (err) {
    console.error('Error getting tracking params', err);

    return {};
  }
};

export default getOrSetTrackingParams;
