import { parseCookies } from 'nookies';

/**
 * Gets the Segment anonymous ID for the current user by parsing the cookie set by Segment
 *
 * @param {object} ctx    The Next.js context object or, if on the client, an object
 *                        containing the query object, ex. { query: {...}}
 *                        https://nextjs.org/docs/api-reference/data-fetching/getInitialProps#context-object
 *
 * @returns {string} The user's anonymous ID assigned by Segment
 */
const getSegmentAnonymousId = (ctx) => {
  const isServer = Boolean(ctx?.res);
  const cookies = isServer ? parseCookies(ctx) : parseCookies();

  return cookies.ajs_anonymous_id ?? '';
};

export default getSegmentAnonymousId;
