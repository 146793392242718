import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ initialValue = null, children }) => {
  const [analytics, setAnalytics] = useState(initialValue);
  const value = useMemo(() => ({ analytics, setAnalytics }), [analytics, setAnalytics]);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.propTypes = {
  initialValue: PropTypes.shape({
    segmentAnonymousId: PropTypes.string,
    hinAnonymousId: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default AnalyticsContext;
