import { parseCookies, setCookie } from 'nookies';
import { v4 as uuidv4 } from 'uuid';

/**
 * Gets or sets a cookie that assigns a random UUID to anonymous users for various
 * tracking purposes.
 * @returns {string}   The UUID/anonymous ID
 */
const getOrSetUserCookie = () => {
  const isServer = typeof window === 'undefined';
  if (isServer) return null;

  const cookieName = process.env.NEXT_PUBLIC_USER_COOKIE;
  let hinAnonymousId;
  const cookies = parseCookies();
  const existingCookie = cookies[cookieName];

  if (existingCookie) {
    hinAnonymousId = existingCookie;
  } else {
    hinAnonymousId = uuidv4();
    try {
      // the cookie should never really expire, so just set/reset it to a year from now
      // every time they visit
      setCookie(null, cookieName, hinAnonymousId, {
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('Unable to set HIN user cookie', err);
    }
  }

  return hinAnonymousId;
};

export default getOrSetUserCookie;
