import Rollbar from 'rollbar';

// const checkIgnore = (isUncaught, args, payload) => {
//   console.log('isUncaught', isUncaught);
//   console.log('args', args);
//   console.log('payload', payload);
// };

const ignoredMessages = [
  // these messages all relate to invalid HTML being rendered in articles
  // mostly divs/tables being rendered inside p tags
  // the pages still render in prod, but throw a lot of noisy errors right now
  'Minified React error #418',
  'Minified React error #423',
  'Minified React error #425',
  // We're seeing a lot of these that seem to come from a Google Ads script
  // Have not been able to replicate so far
  // https://app.rollbar.com/a/capn401dash/fix/item/HI-Next/7167
  'Failed to fetch',
];

const rollbar =
  typeof window === 'undefined'
    ? new Rollbar({
        accessToken: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.NEXT_PUBLIC_HI_ENV,
          client: {
            javascript: {
              code_version: process.env.NEXT_BUILD_ID,
            },
          },
        },
        ignoredMessages,
      })
    : new Rollbar({
        accessToken: process.env.NEXT_PUBLIC_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: process.env.NEXT_PUBLIC_HI_ENV,
          client: {
            javascript: {
              source_map_enabled: true,
              code_version: process.env.NEXT_BUILD_ID,
              guess_uncaught_frames: true,
            },
          },
        },
        hostSafeList: ['humaninterest.com'],
        ignoredMessages,
      });

export default rollbar;
