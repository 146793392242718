/* istanbul ignore file */
import { datadogRum } from '@datadog/browser-rum-slim';
import { datadogLogs } from '@datadog/browser-logs';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  env: process.env.NEXT_PUBLIC_HI_ENV,
  version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
  sessionSampleRate: 100,
  trackUserInteractions: true,
});

datadogLogs.init({
  clientToken: process.env.NEXT_PUBLIC_DATADOG_TOKEN,
  site: process.env.NEXT_PUBLIC_DATADOG_SITE,
  service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
  env: process.env.NEXT_PUBLIC_HI_ENV,
  version: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
  forwardReports: 'all',
  forwardErrorsToLogs: false,
});

// eslint-disable-next-line no-console
console.log(`datadog init version: ${process.env.NEXT_PUBLIC_PACKAGE_VERSION}`);
