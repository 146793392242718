import { useState } from 'react';

import rollbar from 'utils/rollbar';

const useRollbar = () => {
  const [state] = useState(rollbar);

  return state;
};

export default useRollbar;
