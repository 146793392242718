/* istanbul ignore file */
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { analytics } from 'utils/analytics';
import { getSessionData } from 'utils/getSessionData';

export const useSegment = () => {
  const router = useRouter();

  const page = () => {
    analytics.page({
      ...getSessionData(),
    });
  };

  useEffect(() => {
    page();

    const cdnUrl = 'https://cdn.seg.humaninterest.com';
    const apiHost = 'api.seg.humaninterest.com/v1';

    const browserSettings = {
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
      cdnUrl,
    };

    const gpcValue = navigator?.globalPrivacyControl;

    if (gpcValue) {
      analytics.load(
        {
          ...browserSettings,
        },
        {
          integrations: {
            All: false,
            Amplitude: true,
            'Google Analytics 4 Web': true,
            'HubSpot Cloud Mode (Actions)': true,
            'HubSpot Web (Actions)': true,
            Snowflake_2: true,
            'Segment.io': {
              apiHost,
              protocol: 'https',
            },
          },
        }
      );
    } else {
      analytics.load(
        {
          ...browserSettings,
        },
        {
          integrations: {
            'Segment.io': {
              apiHost,
              protocol: 'https',
            },
          },
        }
      );
    }

    router.events.on('routeChangeComplete', page);

    return () => {
      router.events.off('routeChangeComplete', page);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);
};
