/* eslint-disable no-console */
import { parseCookies } from 'nookies';

/**
 * @typedef {object} SessionData
 * @property {string} landing_page The tracked landing page for the session
 * @property {string} referrer The tracked referrer for the session
 */

/**
 * @type {number} Length of a "session" in seconds
 */
export const SESSION_LENGTH = 24 * 60 * 60;

export const SESSION_DATA_STORAGE_KEY = 'hin_session';

/**
 * Sets the session data in a cookie
 * @param {SessionData} data
 */
const setInStorage = (data) => {
  try {
    const domain =
      process.env.NEXT_PUBLIC_HI_ENV === 'development'
        ? 'localhost'
        : '.humaninterest.com';
    const cookieValue = new URLSearchParams(data).toString();
    // this should only ever run in the browser
    document.cookie = `${SESSION_DATA_STORAGE_KEY}=${cookieValue}; max-age=${SESSION_LENGTH}; path=/; domain=${domain}`;
  } catch (err) {
    console.error(`Error storing session data`, err);
  }
};

/**
 *
 * @returns {SessionData}
 */
export const getSessionData = () => {
  if (typeof window === 'undefined') return {};

  try {
    const cookies = parseCookies();

    const existingCookie = cookies[SESSION_DATA_STORAGE_KEY];

    if (existingCookie) {
      const existingRecord = Object.fromEntries(new URLSearchParams(existingCookie));

      if (existingRecord) {
        return { ...existingRecord };
      }
    }

    const data = {
      landing_page: window.location.pathname,
      referrer: document.referrer,
    };
    setInStorage(data);

    return data;
  } catch (err) {
    console.error(`Error getting session data`, err);
    return {};
  }
};
