/* istanbul ignore file */
import Router from 'next/router';
import { parseCookies } from 'nookies';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { getSessionData } from 'utils/getSessionData';
import getOrSetTrackingParams from 'utils/getOrSetTrackingParams';

export const analytics = new AnalyticsBrowser();

export const HUBSPOT_UTK_COOKIE = 'hubspotutk';

/**
 * Sends an analytics "track" event to Segment
 *
 * @param {String} event          Name of the event to be tracked
 * @param {Object} additionalData Any additional data
 */
export const track = (event, additionalData = {}) => {
  const cookies = parseCookies();
  // don't send the hubspot utk value for non-interaction events like web vitals
  const hubspotParams = additionalData.nonInteraction
    ? {}
    : {
        hubspot_utk: cookies[HUBSPOT_UTK_COOKIE],
      };

  const trackingParams = getOrSetTrackingParams(Router.query);
  const sessionData = getSessionData();
  const timezone = window.Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '';
  // eslint-disable-next-line no-unsafe-optional-chaining
  const screen = `${window.screen?.width * window.devicePixelRatio}x${
    // eslint-disable-next-line no-unsafe-optional-chaining
    window.screen?.height * window.devicePixelRatio
  }`;
  analytics.track(event, {
    url: window.location.href,
    path: window.location.pathname,
    title: document.title,
    timezone,
    screen,
    ...hubspotParams,
    ...trackingParams,
    ...sessionData,
    ...additionalData,
  });
};

/**
 * Calls identify in Segment
 *
 * @param {Object} traits  An optional object containing data about the user. See https://segment.com/docs/connections/spec/identify/#traits
 * @param {Object} options Optional configuration data, i.e. { integrations: { 'Salesforce': true }}
 */
export const identify = (traits, options) => {
  analytics.identify(traits, options);
};
