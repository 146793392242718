import { parseCookies } from 'nookies';
import { useEffect } from 'react';

import { track } from 'utils/analytics';

export const MESSAGES_UTK_COOKIE = 'messagesUtk';
export const HUBSPOT_CONVERSATION_EVENTS = {
  CONVERSATION_STARTED: 'conversationStarted',
  CONVERSATION_CLOSED: 'conversationClosed',
  CONTACT_ASSOCIATED: 'contactAssociated',
  UNREAD_CONVERSATION_COUNT_CHANGED: 'unreadConversationCountChanged',
};
export const SEGMENT_CONVERSATION_EVENTS = {
  CONVERSATION_STARTED: 'conversation_started',
  CONVERSATION_CLOSED: 'conversation_closed',
  CONTACT_ASSOCIATED: 'conversation_contact_associated',
  UNREAD_CONVERSATION_COUNT_CHANGED: 'conversation_unread_count_changed',
};

export const useHubSpotConversations = () => {
  useEffect(() => {
    const onConversationsReady = () => {
      const conversationsSdk = window.HubSpotConversations;

      conversationsSdk.on(HUBSPOT_CONVERSATION_EVENTS.CONVERSATION_STARTED, (payload) => {
        // the cookies aren't necessarily set when the "onReady" event fires
        const cookies = parseCookies();
        const additionalData = {
          messages_utk: cookies[MESSAGES_UTK_COOKIE],
        };
        track(SEGMENT_CONVERSATION_EVENTS.CONVERSATION_STARTED, {
          ...payload,
          ...additionalData,
        });
      });
      conversationsSdk.on(HUBSPOT_CONVERSATION_EVENTS.CONVERSATION_CLOSED, (payload) => {
        // the cookies aren't necessarily set when the "onReady" event fires
        const cookies = parseCookies();
        const additionalData = {
          messages_utk: cookies[MESSAGES_UTK_COOKIE],
        };
        track(SEGMENT_CONVERSATION_EVENTS.CONVERSATION_CLOSED, {
          ...payload,
          ...additionalData,
        });
      });
      conversationsSdk.on(HUBSPOT_CONVERSATION_EVENTS.CONTACT_ASSOCIATED, (payload) => {
        // the cookies aren't necessarily set when the "onReady" event fires
        const cookies = parseCookies();
        const additionalData = {
          messages_utk: cookies[MESSAGES_UTK_COOKIE],
        };
        track(SEGMENT_CONVERSATION_EVENTS.CONTACT_ASSOCIATED, {
          ...payload,
          ...additionalData,
        });
      });
      conversationsSdk.on(
        HUBSPOT_CONVERSATION_EVENTS.UNREAD_CONVERSATION_COUNT_CHANGED,
        (payload) => {
          // the cookies aren't necessarily set when the "onReady" event fires
          const cookies = parseCookies();
          const additionalData = {
            messages_utk: cookies[MESSAGES_UTK_COOKIE],
          };
          track(SEGMENT_CONVERSATION_EVENTS.UNREAD_CONVERSATION_COUNT_CHANGED, {
            ...payload,
            ...additionalData,
          });
        }
      );
    };

    if (window?.HubSpotConversations) {
      onConversationsReady();
    } else {
      window.hsConversationsOnReady = [onConversationsReady];
    }
  }, []);
};
