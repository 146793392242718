import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import useRollbar from 'hooks/useRollbar';
import getOrSetTrackingParams from 'utils/getOrSetTrackingParams';
import useAnalytics from 'hooks/useAnalytics';
import getSegmentAnonymousId from 'utils/getSegmentAnonymousId';
import { useHubSpotConversations } from 'hooks/useHubSpotConversations';
import { useSegment } from 'hooks/useSegment';

const Page = ({ children }) => {
  useSegment();
  useRollbar();
  useHubSpotConversations();
  const router = useRouter();
  const { query } = router;
  const {
    analytics: { segmentAnonymousId },
    setAnalytics,
  } = useAnalytics();

  // on initial page load, this cookie won't be set, so keep retrying until it is
  useEffect(() => {
    if (!segmentAnonymousId) {
      const anonymousId = getSegmentAnonymousId();
      setAnalytics((a) => ({ ...a, segmentAnonymousId: anonymousId }));
    }
  }, [segmentAnonymousId, setAnalytics]);

  useEffect(() => {
    getOrSetTrackingParams(query);
  }, [query]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
