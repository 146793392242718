import { useContext } from 'react';

import AnalyticsContext from 'context/analyticsContext';

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsContext provider');
  }
  return context;
};

export default useAnalytics;
