/* istanbul ignore file */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import 'utils/wdyr';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useEffect } from 'react';

import { AnalyticsProvider } from 'context/analyticsContext';
import theme from 'utils/theme';
import { track } from 'utils/analytics';
import getOrSetUserCookie from 'utils/getOrSetUserCookie';
import getSegmentAnonymousId from 'utils/getSegmentAnonymousId';
import 'utils/datadog';
import Page from 'legacy/components/page';
import GlobalStyle from 'legacy/components/globalStyle';
import '@humaninterest/hui/lib/hui.min.css';

function redditPixelScript(w, d) {
  /* eslint-disable */
  if (!w?.rdt) {
    const p = (w.rdt = function () {
      p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
    });
    p.callQueue = [];
    const t = d.createElement('script');
    t.src = 'https://www.redditstatic.com/ads/pixel.js';
    t.async = !0;
    const s = d.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(t, s);
  }
  /* eslint-enable */
}

const MyApp = ({ Component, pageProps, err }) => {
  const modifiedPageProps = {
    ...pageProps,
    err,
  };
  const segmentAnonymousId = getSegmentAnonymousId();
  const hinAnonymousId = getOrSetUserCookie();
  useEffect(() => {
    if (!window || !document) {
      return;
    }
    redditPixelScript(window, document);
    window.rdt('init', 'a2_edefgjvexcwh');
    window.rdt('track', 'PageVisit');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AnalyticsProvider initialValue={{ segmentAnonymousId, hinAnonymousId }}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          useEnterprise
        >
          <Page>
            <Component {...modifiedPageProps} />
          </Page>
          <GlobalStyle />
        </GoogleReCaptchaProvider>
      </AnalyticsProvider>
    </ThemeProvider>
  );
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
  err: PropTypes.instanceOf(Error),
};

export function reportWebVitals({ id, name, label, value }) {
  track(name, {
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: 1, // avoids affecting bounce rate.
  });
}

export default MyApp;
