/**
 * @typedef {Object} expiryRecord
 * @property {string} value
 * @property {Date} expiryDate
 */

/**
 * Returns a record based on the given value and date of expiration
 * @param {*} value The value to store
 * @param {number} expiryPeriod Time in ms the record is valid for
 * @returns
 */
const getExpiryRecord = (value, expiryPeriod) => {
  const expiryDate = new Date().getTime() + expiryPeriod;
  return {
    value,
    expiryDate,
  };
};

export default getExpiryRecord;
